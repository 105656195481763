import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode5.png'

const Episode5 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">Planet monkeys around by a lake singing a love song</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 5: She flows into my valleys</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="Tropical beach with the words overlaid 'She flows into my valleys" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8093711/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome back my friends to Planet and Sky, a cosmic love story.
            </p>

            <p>
              In our narrative so far, scientists exploring a barren planet have discovered some sort of intelligence there, an intelligence that has captured several members of the crew and turned them into participants in a creation myth. Central to this story is the union of a craggy and rocky planet with a gentle and enveloping atmosphere. The concord between this planet and sky, with help from some microbes drifting through the universe in a block of ice, created a lush and populous biosphere. It was a grand epoch in the history of the planet and its sky – the efflorescence of the unlikely romance with which our story first began.
            </p>

            <p>
              Planet had been heaving gigantic slabs of crust around for a long time now and had created broad mountain ranges and deep fertile valleys filled with dark forests populated by energetic creatures of all kinds. Sky had filled the lowlands with cool waters in which all sorts of fish and other marine animals would evolve, swim and proliferate. She had an abundant variety of types of weather with which to entertain Planet. They still had their turbulent occasions, but most of the time their world was now thriving with relatively peaceful life. They loved raising their busy family together.
            </p>

            <p>
              Planet sometimes liked to imagine he was one of the developing creatures on his world enjoying the glorious product of his love with Sky. On one such occasion he chose to cast his perspective into the body of one of the apelike creatures that lived in a small settlement by a mountain lake in a temperate region. It had just finished a morning meal of fresh fish it had caught the previous evening and was now sitting on a rock as it watched the morning sun rise slowly higher into the beautiful blue of the dome of his love, the ever present Sky. As he leaned back and soaked in the morning warmth he reflected upon all the ways he loved and admired his life partner.
            </p>

            <p>
              “She flows into my valleys...” he sang, “dances on my plains, climbs my highest mountains, and brings me gentle rains.
            </p>

            <p>
              Sky meant everything to him. He had a hard time remembering a time in the distant past when he thought he had no need for her. Now, he thrilled to do everything he could to make her happy and healthy. If she needed more of the rich chemicals he could produce for her he could reach deep into his mantle and melt more rock, then build a sturdy volcano and send up a powerful blast of rock, steam and gases for her pleasure. If she needed more mountains to play upon, he would push more crust together to create colossal collisions and make new playgrounds for her. If her sea life was in need of new havens he would produce gigantic basins in which countless and diverse creatures could swim and frolic.
            </p>

            <p>
              He resumed his song. “I provide a base for her, enrich her wondrous soul, whatever she may need from me, I will find and bring it whole.”
            </p>

            <p>
              He thought of all of the different ways he loved her. First, there was the obvious thrill of play and physical interaction with her. The intermixing of their elements that produced such wonders as the rock and soil upon his surface, the natural environments filled with plants and trees and the many kinds of animals living and feeding among them, and most of all the luscious atmosphere with fragrant, rushing air and so much fascinating weather! Of course, he got quite a lot of pleasure out of those kinds of interactions himself.
            </p>

            <p>
              Next, the love of creating things. He had once thought that all that was important was to contemplate his simple geometry and admire its own perfection. How much more rewarding to create wondrous environments, stunning topography, and the conditions necessary to breed living beings capable of enjoying all these fruits of creation for themselves! Sky seemed to enjoy this as much as he did and had a deep connection with all of the life she supported. It was obvious their children loved and appreciated her as much as he did.
            </p>

            <p>
              Then there was the love for each other as dear companions in the long journey through time. Though the lives of their creations were very short - from just millions of years for his mountain ranges to the usually only decades for the lifeforms that lived among them, to the brief hours of her lovely storms - Planet and Sky were companions for all eternity. They had already shared eons of joy and sometimes pain between them, and would continue to be interlinked to the ends of time. They were intermixed to the point of being a single, enduring being.
            </p>

            <p>
              Lastly, and perhaps most importantly, was the love for the children they had brought into being. Each form of life upon his surface, from the swaying trees and grasses, to the buzzing insects, and the gazelles and the beautiful giant cats that preyed upon them, and even the ambitious thinking creatures like the one he was inhabiting right now, all relied upon the natural environment created and maintained by him and his wife. It was their fondest duty to keep this glorious web of life thriving and multiplying.
            </p>

            <p>
              Planet combined these appreciative thoughts into a chorus for Sky. “Such is love - the thrill of combination. Such is love - creating a brand new world. Such is love - joyous eternal friendship… a vow, to guide and shelter all, all within our fold.”
            </p>

            <p>
              He thought about his turbulent past; the long eons of bombardment and the barren plains before life had begun, and the empty atmosphere before Sky had become so healthy and alive, and how much had changed! Now he was warm on the surface and below, and beautiful rivers coursed over his surface, carving valleys and gorges. The travelers who had come from afar were awakened and grown to become entire ecosystems. Life was good.
            </p>

            <p>
              Planet continued his song, “Deep inside I’m warm now - on my surface rivers run. Time to wake the travelers... to thrive beneath the sun.”
            </p>

            <p>
              Then a troubling thought intruded and disturbed his delight. He wasn’t entirely sure of how long this beautiful life would go on. He hadn’t even known Sky was with him at one time. Would she always be? He felt confident that she loved him deeply and would never part from him voluntarily, but would he always have the strength to hold onto her and keep her near?
            </p>

            <p>
              He gave voice to this concern, “And yet my piece is broken. No! This I dare not dwell upon, I know she’ll never leave me, but what if I can’t hold on?”
            </p>

            <p>
              And just as quickly the moment passed. He watched a great bird descend softly and alight in a soaring tree along the lakeside, and realized that this moment was too precious to waste in fretting about the distant future.
            </p>

            <p>
              Sky also enjoyed projecting her awareness into the creatures that inhabited their world, and just now was watching her lover from the eyes of the very bird that Planet had watched as it descended and found a perch in the nearby tree. She knew him well enough to know his thoughts and she shared his joy in their creations.
            </p>

            <p>
              At the same time, she was aware of a larger perspective in space and time. With her reach to the far limits of her upper atmosphere, she knew the universe was a cold and heartless realm largely devoid of the joys and comfort held within their fragile bubble of life. She pondered the value of their love and wished for it to remain as long as possible, knowing that all good things will eventually fade from existence.
            </p>

            <p>
              “Lover draw me close to thee,” she sang, “against the black and cold of night. We hold the key to life within us... fail not your precious hold on me.”
            </p>

            <p>
              Planet noticed the bird and recognized the presence of his wife and picked up her concern for their distant future. Fear not, he thought. We will live together for all eternity. Nothing could ever make me let you go!
            </p>

            <p>
              Sky moved her awareness into a second and slightly smaller ape creature. She stepped up onto the rock now and sidled in beside her mate. Planet reached out and stroked her fur and she leaned into him. Together they watched the sun rising slowly into the rich blue sky and sighed happily. Planet noticed another pair of birds flying high above and glanced at his wife.
            </p>

            <p>
              Moments later they soared on the rising Sun-warmed air and banked together to explore a neighboring valley.
            </p>

            <p>
              Eternity, for the time being at least, could wait.
            </p>

          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode5
